import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, inject } from '@angular/core';
import { ActivatedRoute, Router, RouterOutlet } from '@angular/router';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { AccountInfo, AuthenticationResult, EventMessage, EventType, SilentRequest, SsoSilentRequest 
} from '@azure/msal-browser';
import { FuseConfigService } from '@fuse/services/config';
import { Subject, filter, takeUntil, tap } from 'rxjs';
import { AppEmbeddedService } from '@itm/services/app.embedded.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    standalone: true,
    changeDetection:ChangeDetectionStrategy.Default,
    imports: [RouterOutlet],
})
export class AppComponent implements OnInit, OnDestroy {
  private readonly _destroying$ = new Subject<void>();
  private readonly _msalBroadcastService = inject(MsalBroadcastService);
  private readonly _appEmbeddedService = inject(AppEmbeddedService);
  private readonly _activatedRoute = inject(ActivatedRoute);
  private readonly _msalService = inject(MsalService);
    
  parentEmbeddedAppTypeId: any = 0
  embeddedAppType: any = {
    All: 0,
    Connect: 1,
    OnSiteLive: 2,
  }

  constructor(private router: Router, private _fuseConfigService: FuseConfigService) {

  }

  ngOnInit(): void {
    // console.log('app.component... ngOnInit()')    
    this._activatedRoute.queryParams.subscribe(parms => { 
      // console.log(parms)
      if([this.embeddedAppType.Connect.toString(), this.embeddedAppType.OnSiteLive.toString()].includes(parms.parent) 
          && parms.layout == 'empty'){
        this.parentEmbeddedAppTypeId = parms.parent;

        this._appEmbeddedService.setAppEmbeddedType({
          parent: parms.parent,
          parlayoutent: parms.layout,
        })
        this.setLayout(parms.layout);

        if(parms.accessToken){
          localStorage.setItem("accessToken", parms.accessToken);
        }
        return;
      }
    });

    this._msalBroadcastService.msalSubject$.pipe(
        // tap(x => console.log('msalSubject=>', x)),
        filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS),
        takeUntil(this._destroying$)
      )
      .subscribe((result: EventMessage) => {
        // Casting payload as AuthenticationResult to access account
        const payload = result.payload as AuthenticationResult;
        // console.log('payload', payload);
        this._msalService.instance.setActiveAccount(payload.account);

        const token = payload.accessToken;
        
        localStorage.setItem('idToken', payload.idToken);
        localStorage.setItem('accessToken', token);
        // localStorage.setItem('uniqueId', payload.uniqueId);
        // this.#ItmUserService.setUserId('6e182ea8-3a04-4d52-8532-b535848646f2');
      });

    this._msalBroadcastService.msalSubject$.pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.ACQUIRE_TOKEN_SUCCESS),
        takeUntil(this._destroying$)
      ).subscribe((result: any) => {
        // Do something with event payload here
        //   console.log('ACQUIRE_TOKEN_SUCCESS',result);
        const payload = result.payload;
        localStorage.setItem('accessToken',payload.accessToken);              
      });
  }

  ngOnDestroy(): void {
      this._destroying$.next(null);
      this._destroying$.complete();
  }

  setLayout(layout: string): void {
    // Clear the 'layout' query param to allow layout changes
    this.router
        .navigate([], {
            queryParams: {
                layout: layout,
            },
            queryParamsHandling: 'merge',
        })
        .then(() => {
            // Set the config
            this._fuseConfigService.config = { layout };
        });
  }

}
