import { Route } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { initialDataResolver } from 'app/app.resolvers';
import { LayoutComponent } from 'app/layout/layout.component';
import { PublicActionCentreListComponent } from './modules/admin/cac/action-centre/public-action-centre-list.component';
import { LayoutResolver } from '@itm/services/layout.resolvers';
import { canDeactivateGuard } from '@itm/guards';

// @formatter:off
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const appRoutes: Route[] = [

    // Redirect empty path to '/example'
    { path: '', pathMatch: 'full', redirectTo: 'home' },
    {
        path: 'public',
        component: LayoutComponent,
        children: [
            {
                path: 'action-centre',
                data:{ pageKey:'cac.action-centre.list' },
                component: PublicActionCentreListComponent,
                resolve: { config: LayoutResolver },
                canDeactivate: [ canDeactivateGuard ]
            },
        ]
      },
      
    // Admin routes
    {
        path: '',
        canActivate: [MsalGuard],
        canActivateChild: [MsalGuard],
        component: LayoutComponent,
        resolve: {
            initialData: initialDataResolver, 
        },
        children: [
            { path: 'system-settings', loadChildren: () => import('app/modules/admin/system-settings/system-settings.routes') },
            { path: 'cac', loadChildren: () => import('app/modules/admin/cac/cac.routes') },
        ]
    },
];
