import { inject, Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, from } from 'rxjs';
import { switchMap, catchError } from 'rxjs/operators';
import { MsalService } from '@azure/msal-angular';
import { AuthService } from './core/auth/auth.service';
import { AuthUtils } from './core/auth/auth.utils';
import { environment } from 'environments/environment';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    private readonly authService = inject(AuthService);
    
  constructor(private msalService: MsalService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // console.log(request)
    // console.log(this.authService.accessToken)

    // Clone the request object
    let newReq = request.clone();

    if (this.authService.accessToken && !AuthUtils.isTokenExpired(this.authService.accessToken)) {
        // console.log('Token Not Expired...')
        newReq = request.clone({
            headers: request.headers.set(
                'Authorization',
                'Bearer ' + this.authService.accessToken
            ),
        });
        return next.handle(newReq);
    }
    // console.log(this.authService.accessToken)

    const account = this.msalService.instance.getActiveAccount();
    // console.log(account)
    if (account) {
      const tokenRequest = {
        scopes: [environment.allApi_scopes],
        account,
      };

      // Use `from` to convert Promise to Observable
      return from(this.msalService.instance.acquireTokenSilent(tokenRequest)).pipe(
        switchMap(response => {
          // Clone the request and add the Authorization header
          newReq = request.clone({
            setHeaders: {
              Authorization: `Bearer ${response.accessToken}`,
            },
          });

          // Pass the cloned request to the next handler
          return next.handle(newReq);
        }),
        catchError(error => {
          console.error('Interceptor token acquisition error:', error);
          // Proceed with the original request if token acquisition fails
          return next.handle(newReq);
        })
      );
    }

    // If no account is found, proceed with the original request
    return next.handle(newReq);
  }
}
